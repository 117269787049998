import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
//const DefaultLayout = React.lazy(() => import('./views/pages/login/Login'))
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
//const DefaultLayout = React.lazy(() => import('./views/home/Home'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Mungmunity - Register(Specialist)
const RegisterSpecialist = React.lazy(() => import('./views/pages/register/RegisterSpecialist'))
const RegisterSpecialistAddData = React.lazy(() =>
  import('./views/pages/register/RegisterSpecialistAddData'),
)
const RegisterSpecialistOverview = React.lazy(() =>
  import('./views/pages/register/RegisterSpecialistOverview'),
)
// Mungmunity - Register(Place)
const RegisterPlace = React.lazy(() => import('./views/pages/register/RegisterPlace'))
const RegisterPlaceAddData = React.lazy(() => import('./views/pages/register/RegisterPlaceAddData'))
const RegisterPlaceOverview = React.lazy(() =>
  import('./views/pages/register/RegisterPlaceOverview'),
)
const RegisterComplete = React.lazy(() => import('./views/pages/register/RegisterComplete'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/admin/*" name="Admin Page" element={<DefaultLayout />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              exact
              path="/registerspecialist"
              name="RegisterSpecialist"
              element={<RegisterSpecialist />}
            />
            <Route
              exact
              path="/registerspecialistadddata"
              name="RegisterSpecialistAddData"
              element={<RegisterSpecialistAddData />}
            />
            <Route
              exact
              path="/registerspecialistoverview"
              name="RegisterSpecialistOverview"
              element={<RegisterSpecialistOverview />}
            />
            <Route exact path="/registerplace" name="RegisterPlace" element={<RegisterPlace />} />
            <Route
              exact
              path="/registerplaceadddata"
              name="RegisterPlaceAddData"
              element={<RegisterPlaceAddData />}
            />
            <Route
              exact
              path="/registerplaceoverview"
              name="RegisterPlaceOverview"
              element={<RegisterPlaceOverview />}
            />
            <Route
              exact
              path="/registercomplete"
              name="RegisterComplete"
              element={<RegisterComplete />}
            />
            <Route path="" name="Home" element={<Login />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
